import {RouteInfo} from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

    {
        path: '/dashboard',
        title: 'Dashboard',
        icon: 'ft-home',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/sub-admin',
        title: 'Manage Sub Admin',
        icon: 'ft-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/company',
        title: 'Company',
        icon: 'ft-user-plus',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    /*{
        path: '/employees',
        title: 'Employees',
        icon: 'ft-user-plus',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },*/
    {
        path: '/manage-bookings',
        title: 'View All Bookings',
        icon: 'ft-book',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/partners',
        title: 'Manage Partners',
        icon: 'ft-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-partners',
        title: 'Partners Request',
        icon: 'ft-user-plus',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-top-10-partners',
        title: 'Manage Top 10 Partners',
        icon: 'ft-map-pin',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-referred-doctors',
        title: 'Manage Referred Doctors',
        icon: 'ft-clipboard',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-customers',
        title: 'Manage Customers',
        icon: 'ft-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-industry',
        title: 'Manage Industry',
        icon: 'ft-server',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-specialty',
        title: 'Manage Specialty',
        icon: 'ft-server',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '',
        title: 'Manage CMS',
        icon: 'ft-list',
        class: 'has-sub',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        submenu: [
            {path: '/manage-cms', title: 'CMS Pages', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {path: '/manage-faq', title: 'FAQ', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
            {path: '/contact', title: 'Contact', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
        ]
    },
    {
        path: '/past-notification',
        title: 'Send Notification',
        icon: 'ft-clipboard',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-advertisement',
        title: 'Manage Advertisement',
        icon: 'ft-airplay',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-promocode',
        title: 'Manage Promo Codes',
        icon: 'ft-package',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-payout',
        title: 'Manage Payouts',
        icon: 'ft-pocket',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-payout-old',
        title: 'Manage Payouts Old',
        icon: 'ft-pocket',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/config', title: 'Costs Settings', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/manage-locations',
        title: 'Manage Locations',
        icon: 'ft-navigation',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-health-insurance',
        title: 'Manage  Insurance Co.',
        icon: 'ft-briefcase',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-cites',
        title: 'Manage Cities',
        icon: 'ft-map-pin',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-medication',
        title: 'Manage  Medication',
        icon: 'ft-briefcase',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/manage-invoice',
        title: 'Doctors & Invoices',
        icon: 'ft-clipboard',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    }

];
