import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../pages/main/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'manage-cms',
        loadChildren: () => import('../../pages/main/manage-cms/manage-cms.module').then(m => m.ManageCmsModule)
    },
    {
        path: 'manage-faq',
        loadChildren: () => import('../../pages/main/manage-faq/manage-faq.module').then(m => m.ManageFaqModule)
    },
    {
        path: 'config',
        loadChildren: () => import('../../pages/main/config/config.module').then(m => m.ConfigModule)
    },
    {
        path: 'manage-industry',
        loadChildren: () => import('../../pages/main/manage-industry/manage-industry.module').then(m => m.ManageIndustryModule)
    },
    {
        path: 'manage-referred-doctors',
        loadChildren: () => import('../../pages/main/manage-referred-doctors/manage-referred-doctors.module').then(m => m.ManageReferredDoctorsModule)
    },
    {
        path: 'manage-advertisement',
        loadChildren: () => import('../../pages/main/manage-advertisement/manage-advertisement.module').then(m => m.ManageAdvertisementModule)
    },
    {
        path: 'manage-promocode',
        loadChildren: () => import('../../pages/main/manage-promocode/manage-promocode.module').then(m => m.ManagePromocodeModule)
    },
    {
        path: 'manage-locations',
        loadChildren: () => import('../../pages/main/manage-locations/manage-locations.module').then(m => m.ManageLocationsModule)
    },
    {
        path: 'manage-health-insurance',
        loadChildren: () => import('../../pages/main/manage-health-insurance/manage-health-insurance.module').then(m => m.ManageHealthInsuranceModule)
    },
    {
        path: 'manage-cites',
        loadChildren: () => import('../../pages/main/manage-cites/manage-cites.module').then(m => m.ManageCitesModule)
    },
    {
        path: 'manage-partners',
        loadChildren: () => import('../../pages/main/manage-partners/manage-partners.module').then(m => m.ManagePartnersModule)
    },
    {
        path: 'manage-specialty',
        loadChildren: () => import('../../pages/main/manage-speciality/manage-speciality.module').then(m => m.ManageSpecialityModule)
    },
    {
        path: 'manage-customers',
        loadChildren: () => import('../../pages/main/manage-customers/manage-customers.module').then(m => m.ManageCustomersModule)
    },
    {
        path: 'partners',
        loadChildren: () => import('../../pages/main/partners/partners.module').then(m => m.PartnersModule)
    },
    {
        path: 'company',
        loadChildren: () => import('../../pages/main/company/company.module').then(m => m.CompanyModule)
    },
    /*{
        path: 'employees',
        loadChildren: () => import('../../pages/main/employees/employees.module').then(m => m.employeesModule)
    },*/
    {
        path: 'past-notification',
        loadChildren: () => import('../../pages/main/send-notification/send-notification.module').then(m => m.SendNotificationModule)
    },
    {
        path: 'manage-payout',
        loadChildren: () => import('../../pages/main/manage-payout/manage-payout.module').then(m => m.ManagePayoutModule)
    },
    {
        path: 'manage-payout-old',
        loadChildren: () => import('../../pages/main/manage-payout-old/manage-payout.module').then(m => m.ManagePayoutModule)
    },
    {
        path: 'manage-bookings',
        loadChildren: () => import('../../pages/main/manage-booking/manage-booking.module').then(m => m.ManageBookingModule)
    },
    {
        path: 'manage-medication',
        loadChildren: () => import('../../pages/main/manage-medication/manage-medication.module').then(m => m.ManageMedicationModule)
    },
    {
        path: 'contact',
        loadChildren: () => import('../../pages/main/contact/contact.module').then(m => m.ContactModule)
    },
    {
        path: 'manage-top-10-partners',
        loadChildren: () => import('../../pages/main/manage-top-ten-partners/manage-top-ten-partners.module').then(m => m.ManageTopTenPartnersModule)
    },
    {
        path: 'maintenance',
        loadChildren: () => import('../../pages/main/maintenance/maintenance.module').then(m => m.MaintenanceModule)
    },
    {
        path: 'manage-invoice',
        loadChildren: () => import('../../pages/main/manage-invoice/manage-invoice.module').then(m => m.ManageInvoiceModule)
    },
    {
        path: 'sub-admin',
        loadChildren: () => import('../../pages/main/manage-sub-admin/manage-sub-admin.module').then(m => m.ManageSubAdminModule)
    },

];

