import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from "../services/api.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SecurityPasswordModuleMessage} from "../Validation/message";
import {CSHelper} from "../../helpers";
import {ChangePasswordModuleMessage} from '../Validation/message';


@Component({
    selector: 'app-security-password',
    templateUrl: './security-password.component.html',
    styleUrls: ['./security-password.component.scss']
})

export class SecurityPasswordComponent implements OnInit {
    securityForm: FormGroup;
    submitted = false;
    message = SecurityPasswordModuleMessage;
    commonMessage = ChangePasswordModuleMessage;
    hide = true;
    hide1 = true;

    constructor(private api: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder, private CSHelper: CSHelper) {
    }

    ngOnInit(): void {
        this.securityForm = this.formBuilder.group({
                oldPassword: ['', [Validators.required, Validators.minLength(6)]],
                newPassword: ['', [Validators.required, this.CSHelper.spaceValidator, Validators.minLength(6)]],
            },
            {
                validator: [PasswordValidation.OldMatchPassword] // your validation method
            });
    }

    onSubmitSecurityForm() {
        this.submitted = true;
        if (this.securityForm.valid) {
            const formData = new FormData();
            formData.append('old_password', this.securityForm.value.oldPassword.trim());
            formData.append('password', this.securityForm.value.newPassword.trim());
            this.api.securityPassword(formData).subscribe((res) => {
                this.CSHelper.hideSpinner();
                if (res.meta.code === 1) {
                    this.CSHelper.showSuccessMessage(res.meta.message);
                    this.securityForm.reset();
                    this.CSHelper.closeModal();
                } else {
                    this.CSHelper.showErrorMessage(res.meta.message);
                }
            }, error1 => {
                this.CSHelper.hideSpinner();
            });

        }

    }

    get f() {
        return this.securityForm.controls;
    }

    // Open modal with dark section
    openModal(changePassword) {
        this.modalService.open(changePassword, {windowClass: 'dark-modal'});
    }

    closebutton() {
        this.securityForm.reset();
        this.modalService.dismissAll();
    }


}


/**

 Confirm password *
 @param {AbstractControl} control
 @returns {{passwordsNotMatch: boolean}}
 */
export class PasswordValidation {

    static OldMatchPassword(AC: AbstractControl) {

        const password = AC.get('newPassword').value; // to get value in input tag
        const oldPassword = AC.get('oldPassword').value; // to get value in input tag
        if (AC.get('newPassword').errors && !AC.get('newPassword').errors.OldMatchPassword) {

            // return if another validator has already found an error on the matchingControl
            return;
        }
        if (password === oldPassword) {
            AC.get('newPassword').setErrors({OldMatchPassword: true});

        } else {
            AC.get('newPassword').setErrors(null);
        }
    }
}

