<a (click)="openModal(changePassword)">
    <i class="ft-lock mr-2"></i>
    <span>Set Security Password</span>
</a>

<ng-template #changePassword let-c="close" let-d="dismiss">
    <form class="form" (ngSubmit)="onSubmitSecurityForm()" [formGroup]="securityForm">
        <div class="modal-header">
            <h4 class="modal-title">Set Security Password</h4>
            <button type="button" class="close" aria-label="Close" (click)="closebutton()">
                <span aria-hidden="true">&times;</span>
            </button>

        </div>
        <div class="modal-body">
            <div class="form-body">
                <div class="form-group">
                    <label>Enter Current Password<span class="red">*</span></label>
                    <div class="position-relative  has-icon-right">
                    <input type="password" class="form-control" formControlName="oldPassword" [type]="hide ? 'password' : 'text'">
                        <div class="form-control-position">
                            <i class="{{hide ? 'ft-eye' :'ft-eye-off'}} " aria-label="Close" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            </i>
                        </div>
                    <small *ngIf="submitted && f.oldPassword.errors" class="form-text text-muted danger">
                        <div *ngIf="f.oldPassword.errors.required" class="validation-error">
                            {{message.securityPassword.required.currentPass}}
                        </div>
                        <div *ngIf="f.oldPassword.errors?.minlength" class="validation-error">
                            {{message.securityPassword.invalid.currentPass}}
                        </div>

                    </small>
                </div>
                </div>

                <div class="form-group">
                    <label>New Password<span class="red">*</span></label>
                    <div class="position-relative  has-icon-right">
                    <input type="password" class="form-control" formControlName="newPassword" [type]="hide1 ? 'password' : 'text'">
                        <div class="form-control-position">
                            <i class="{{hide1 ? 'ft-eye' :'ft-eye-off'}} " aria-label="Close" (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                            </i>
                        </div>
                    <small *ngIf="submitted && f.newPassword.errors" class="form-text text-muted danger">
                        <div *ngIf="f.newPassword.errors.required" class="validation-error">
                            {{message.securityPassword.required.newPass}}
                        </div>
                        <div *ngIf="f.newPassword.errors?.minlength" class="validation-error">
                            {{message.securityPassword.invalid.newPass}}
                        </div>
                        <div
                                *ngIf="f.newPassword.errors.OldMatchPassword"
                                class="validation-error"> {{commonMessage.changePassword.match.oldAndNewPass}}
                        </div>

                    </small>
                </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-raised btn-primary">
                <i class="fa fa-check-square-o"></i> Submit
            </button>
            <button type="button" class="btn btn-secondary btn-raised" (click)="closebutton()">
                Close
            </button>
        </div>
    </form>
</ng-template>
