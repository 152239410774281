<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse"
                    (click)="toggleSidebar()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <span class="d-lg-none navbar-right navbar-collapse-toggle">
                <a class="open-navbar-container" (click)="isCollapsed = !isCollapsed"
                   [attr.aria-expanded]="!isCollapsed" aria-controls="navbarSupportedContent">
                    <i class="ft-more-vertical"></i>
                </a>
            </span>
            <div class="content-header">
                {{api.pageTitle}}
            </div>
        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse custom-navbar" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
                <ul class="navbar-nav">
                    <li class="nav-item" ngbDropdown [placement]="placement">
                        <a class="nav-link position-relative" id="dropdownBasic3" ngbDropdownToggle>
                            <i class="ft-user font-medium-3 blue-grey darken-4"></i>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="text-left" >
                            <a class="dropdown-item py-1">
                                <app-change-password></app-change-password>
                            </a>
                            <a class="dropdown-item py-1" *ngIf="hiddenNav">
                                <app-security-password></app-security-password>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" (click)="logout()">
                                <i class="ft-power mr-2"></i>
                                <span>Logout</span>
                            </a>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- Navbar (Header) Ends -->
