// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    //apiBaseUrl : 'https://staging-api.waitless.com.do/admin/v1/',
    apiBaseUrl : 'https://backend.waitless.com.do/admin/v1/',
    apiBaseUrlSecond : 'https://backend.waitless.com.do/company/v3/',
    apiAddEmployes : 'https://backend.waitless.com.do/company/v3/',
    //apiBaseUrl : 'http://localhost:8080/admin/v1/',
    production: false,
    tokenCompany: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5IiwianRpIjoiNmEzYTNiN2FjNzI2YTg4NTBjMmZkNjkyNzU3OTdlNmE0OGYyNDA1NzlmNTMyYTNiYzlmNmY2YTJmNTc2MjFjYjNlZGNlNTgyNTE1NTgyYzciLCJpYXQiOjE2NzUxOTgxMDcsIm5iZiI6MTY3NTE5ODEwNywiZXhwIjoxNzA2NzM0MTA3LCJzdWIiOiI0MDYiLCJzY29wZXMiOltdfQ.PEN4yJc1qjK90HWX6s2jRqV4jl3f-MvrGrN-H2s8yMA2rar1L7izyYiW3qNPuQTTAFH5WgsK-uKbyMQB3vrPEANVlt6WNby8BCJXh1_kIYmU3T5tl3tHNa4BAjdoDyERnnA8p4zAxOvKtnubz896mE_DhDGhjUQpUXk1oMi4XC40XHTvbyD9b7CTcq6B--juplJ0Pc10AyJHXQqRDywv4v5Q4hW97S9hEaG4RLLB_f1Mqv6u6DxETo2l2T90phbl1wtKma2HoKqjoRAT5pQPp8x1pQcoEYevEw335nGUeIL2VCy_AwYBDa972fQritbDmfvpofGM71gpj9EA7MOKZL8oazabGE0ZqlefuaR4ZyNQTmE_iDf_9vOx1C013RJMMstV__jev3qAAGYkJqXiZxflcNPKWmreeoDjhDfSMdB59F1Iy3uwanSIBtmsq3hcTvD5MVMt8hNrd0Hflk0P2JYXm-W2UTQdIioPW8qYwmrzFZryjFxHeJZawmXZ81oDmAZ6n4haIaI4ScPCq3lToTR3pjYaY4fn19uCmf0ktJCWh2BgVOfWyuOMW9xriG_UzBglNv9CB-qSTM1lxd50nKpS6hkbPKmKPZOrJRkoyTQopupXqV7Wm6NN2mY7p--2aEpTELHh9liU5k8z8Y7zNCrPPjF4_vXAPh36A5Gy1Eg',
    //tokenCompany: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5IiwianRpIjoiMzhlMWYwYTY0NWEzYTM4MDQ0ODAyODQyZjk4MWYzMWEwMWUxNjg1MTcxN2I4ZDQxZmFlMDBhMTJiMzQxOWJiODFhZjIxYWFmMjQyNzBiYTkiLCJpYXQiOjE2NjczNDQ5NDQsIm5iZiI6MTY2NzM0NDk0NCwiZXhwIjoxNjk4ODgwOTQ0LCJzdWIiOiIxNTc4Iiwic2NvcGVzIjpbXX0.zCYbTiPp16DMpFtck3KM2CeTtH9xquogS6zVxg6N9BgcEIs_xfcAWvl3Xm5F3qGxrMFSn7tpymZDfB--mW4i4VBdFRbsCuWIVUxTuG_ee5kIdUH97azZw0C56G6k6T8zc4BWOgX3vvCvEQqz8CkO8RXUybc0X3prU9pwm_zZ0pJ0RdGz0XM2JATLySir0ExoFRyt7-4Bdlc5qs4RHIxLiQ-H702AoOSefahe7QsgbNIU9kRp3J0f65oSk4WeWMKhxnREkTdkVF9Io0TROcesFYNkUSPcdV6nfcAq9onQb_U5tQ8mtVh6SDXVKqKTiy7rAbBkkuYo78H0rLCLevvDcO-5x31J6MDJErreZNbCCBo2RkPBaqcBAN6WHbYqO__yAM4OdGG-5wbDt06w9RQcsa_rPf6Ezoy-jmugrX3gX6Ibf_4KF5nqHTxuGIr3rWxaDuQpMdHecRfzMnRt9PAGTKN8YVz7CRzlCIspc4zsdu1yPpa40pi7uO731tjK9pXqSFMvFmvWsDYaqZsv0UQcKVzHdHwLs_8IFotHjn6u3FVooaAcjCRSTQQukOns0tz2_z8czQgxBwpXW9X6sFwrpRtBuNVnNKn3xipSk0eabKYfZFahRxkcFUHBIwLEMrrXvFlRk-pH5222stBHuxXN262sHe7fKNL9QzyypYZ2yVY'
};
