import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpErrorResponse} from '@angular/common/http';
import {AbstractControl} from '@angular/forms';
import * as moment from 'moment';
import {timer} from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class  CSHelper {
    public name: any;
    public email: any;
    confirmationMsg = 'Are you sure you want to delete this';
    industryImage = {
        fileObject: null,
        base64: null
    };
    coverImageImage = {
        fileObject: null,
        base64: null
    };
    currency = 'RD$';
    noDataMsg = 'No Data Available.';
    public perPage = 50;
    public defaultUser = 'assets/img/default-user.png';
    public defaultIndustry = 'assets/img/default.png';
    public defaultPDF = 'assets/img/pdf-default.png';
    public defaultDocx = 'assets/img/doc-default.png';
    previewImageSize = '?w=45&h=45&fit=crop';
    previewLocationImage = '?w=70&h=70&fit=crop';
    buttonTitle: string;
    totalRecord = [10, 20, 30, 50];
    countDown: any;
    tick = 1000;
    retryCount = 0; // 60 seconds
    resquestPara: any = {};
    allowedFileExtension = ['jpg', 'png', 'jpeg'];
    selectIndustry: any;
    invoiceImage = '?w=100&h=100&fit=crop';
    constructor(private toastr: ToastrService, private modalService: NgbModal, private router: Router, private spinner: NgxSpinnerService) {
    }

    keyPress(event: any) {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    getFormattedImage(file: any) {
        const fileReader = new FileReader();
        return new Promise(resolve => {
            fileReader.readAsDataURL(file);
            fileReader.onload = (e: any) => {
                resolve(e.target.result);
            }
        });
    }

    // Llamar alerta
    getAlertUtils(handle: any, title: string, message: string){
        Swal.fire(title,message, handle)
    }


    getFormattedOnlyDate(date): string {
        return moment(date, 'YYYY-MM-DD hh:mm A').utc(date).local().format('DD-MM-YYYY')

    }

    setDatepickerData(data) {
        if (data !== undefined) {
            const splitArray = data.split('-');
            return {
                year: Number(splitArray[2]),
                month: Number(splitArray[1]),
                day: Number(splitArray[0])
            }
        }
    }

    onProfileSelect($event: any) {
        this.industryImage.fileObject = $event.target.files[0];
        if (this.validateFile(this.industryImage.fileObject.name, this.industryImage.fileObject.size)) {
            this.getFormattedImage(this.industryImage.fileObject).then((base64) => {
                this.industryImage.base64 = base64;
            });
        } else {
            this.industryImage = {
                fileObject: null,
                base64: null
            };
        }
    }

    coverImageSelect($event: any) {
        this.coverImageImage.fileObject = $event.target.files[0];
        if (this.validateFile(this.coverImageImage.fileObject.name, this.coverImageImage.fileObject.size)) {
            this.getFormattedImage(this.coverImageImage.fileObject).then((base64) => {
                this.coverImageImage.base64 = base64;
            });
        } else {
            this.coverImageImage = {
                fileObject: null,
                base64: null
            };
        }
    }

    validateFile(name, size) {
        const ext = name.substring(name.lastIndexOf('.') + 1);
        const Size = size / 1024 / 1024;

        if (ext.toLowerCase() === 'png' || ext.toLowerCase() === 'jpeg' || ext.toLowerCase() === 'jpg' || ext.toLowerCase() === 'gif') {
            if (ext.toLowerCase() !== 'mp4') {
                if (Size > 10) {
                    this.toastr.error('Image size can not be more than 10 MB.');
                } else {
                    return true;
                }
            }
        } else {
            this.toastr.error('Please upload a valid image.');
            return false;
        }

    }

    openModal(add) {
        this.modalService.open(add, {windowClass: 'dark-modal'});
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    commissonValue(event: any) {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode != 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
        if (event.target.value > 100) {
            event.target.value = 100;
        }
    }

    public redirectToPage(type, id?): void {
        this.router.navigate([type]);
    }

    showSuccessMessage(message): void {
        this.toastr.success(message);
    }

    showErrorMessage(message): void {
        this.toastr.error(message);
    }

    public clearLocastorage(): void {
        localStorage.clear();
    }

    showSpinner() {
        this.spinner.show();
    }

    hideSpinner() {
        this.spinner.hide();
    }

    public handleAuthError = (err: HttpErrorResponse): void => {
        const errorMessage = err.error.message;
        // TODO : Error handling
        if (errorMessage) {
            this.showErrorMessage(errorMessage);
        }
        switch (err.status) {
            case 0   :
                this.clearLocastorage();
                this.redirectToPage('/login');
                break;
            case 400 :
                break;
            case 401 :
                this.clearLocastorage();
                this.redirectToPage('/login');
                break; //  Unauthorized;
             case 406 :
                this.redirectToPage('/dashboard');
                break; //  Unauthorized;
            case 500 :
                this.clearLocastorage();
                this.redirectToPage('/login');
                break; //  Internal Server Error;
            case 503 :
                this.clearLocastorage();
                //this._helper.retryCount = err.error.retry;
                this.redirectToPage('/maintenance');
                break; //  Service Unavailble
        }
    }

    retryAfter(): void {
        const retry = this.retryCount;
        if (retry > 0) {
            this.countDown = timer(0, this.tick)
                .subscribe(x => {
                    --this.retryCount;
                    if (x === retry) {
                        this.countDown.unsubscribe();
                        this.redirectToPage('login');
                    }
                });

        } else {
            this.redirectToPage('dashboard');
        }
    }

    getFormatedOnlyDate(date): string {
        return moment(date, 'YYYY-MM-DD').utc(date).local().format('DD-MM-YYYY')
    }
    dateFormatted(date) {
        return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
    }

    getFormatedOnlyDateTime(date): string {
        return moment(date).utc(date).local().format('DD MMM YYYY, hh:mm A')
    }

    showCharacterLimit(text, count): string {
        return text.slice(0, count) + (text.length > count ? '...' : '');
    }

    setDefaultImage(event, type): void {
        switch (type) {
            case 'user_profile':
                event.target.src = this.defaultUser;
                break;
            case 'default_industry':
                event.target.src = this.defaultIndustry;
        }
    }

    spaceValidator(control: AbstractControl) {
        if (control && control.value && !control.value.replace(/\s/g, '').length) {
            control.setValue('');
            return {required: true}
        } else {
            return null;
        }
    }

    reverseDate(date): string {
        return date.split('-').reverse().join('-');
    }

    arrayToString(data: Array<string>): string {
        let str = '';
        if (data && data.length > 0) {
            data.map(res => str = `${str}${str.length > 0 ? ',' : ''}${res}`);
        }
        return str;
    }

    getTotalRows(arrayLength, page, perPage) {
        return arrayLength < Number(perPage) ? arrayLength + (Number(perPage) * (page - 1)) : arrayLength * page;
    }

    getFormattedOnlyDateRequest(date): string {
        return moment(date, 'DD-MM-YYYY').utc(date).local().format('YYYY-MM-DD')

    }

    public convert24HourInto12Hour(time) {
        const timeString = time;
        if (timeString === null || timeString === '') {
            return '';
        } else {
            return new Date('1970-01-01T' + timeString + 'Z').toLocaleTimeString('en-US',
                {timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric'}
            );
        }
    }

    getFormattedDateForBackend(data) {
        const day = data.day.toString().length < 2 ? '0' + data.day.toString() : data.day.toString();
        const month = data.month.toString().length < 2 ? '0' + data.month.toString() : data.month.toString();
        return `${data.year}-${month}-${day}`;
    }
    transform(time: any): any {
        if(time){
            let hour = (time.split(':'))[0]
            let min = (time.split(':'))[1]
            let part = hour > 12 ? 'pm' : 'am';
            min = (min+'').length == 1 ? `0${min}` : min;
            hour = hour > 12 ? hour - 12 : hour;
            hour = (hour+'').length == 1 ? `0${hour}` : hour;
            return `${hour}:${min} ${part}`
        }

    }

    getUrlExtension( url ): any {
        return url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
    }

    public numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        return !(charCode > 31 && (charCode < 48 || charCode > 57));
    }
    payoutDateFormat(date): string {
        return moment(date, 'YYYY-MM-DD').utc(date).local().format('DD MMM YYYY')
    }

}

