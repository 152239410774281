export const LoginModuleMessage = {
    login: {
        required: {
            email: 'Please enter your email.',
            pass: 'Please enter password.'
        },
        invalid: {
            email: 'Please enter a valid email.'
        }
    }
};
export const ConfigModuleMessage = {
    config: {
        required: {
            vipCode: 'Please enter cost of VIP Turn.',
            subscriptionAmount: 'Please enter subscription amount.',
            commission: 'Please enter commission %.',
            booking_fee: 'Please enter booking fees.',
            discount: 'Please Enter pay now discount.'
        }
    }
};
export const CMSModuleMessage = {
    cms: {
        required: {
            answer: 'Please enter the CMS Details.',
            faqAnswer: 'Please mention answer for the question.',
            question: 'Please enter the Question.',
            title: 'Please enter the title.',
            faq_type:'Select any audience.'
        }
    }
};
export const AdvertisementModuleMessage = {
    advertisement: {
        required: {
            image: 'Please upload advertisement Image.',
            name: 'Please enter name.',
            mobile: 'Please enter mobile number.',
            startDate: 'Please select start date.',
            endDate: 'Please select end date.',
            industry: 'Please select a industry.',
        },
        invalid: {
            url: 'Please enter valid url.',
            mobile: 'Please enter valid mobile number.',

        }

    }
};
export const AddIndustryModuleMessage = {
    addIndustry: {
        required: {
            image: 'Please upload Industry Image.',
            bothLang: 'Please add information in both the languages.',
            name: 'Please enter  Name.',
            education: 'Please enter Education title.',
            diploma: 'Please enter Diploma and Awards title.',
            insurance: 'Please enter Insurance Companies title.',
            referreDoctors: 'Please enter Referred Doctors/Partners.',
            availabilityTime: 'Please enter Availability Timings title.',
            telemedicine: 'Please enter Telemedicine title.',
            emergencyTelemedicine: 'Please enter Emergency Telemedicine Timings title.',
            rateAndReview: 'Please enter Rate and Review title.',
            telemedicineConsultation: 'Please enter Telemedicine Consultation title.',
            away: 'Please enter Away title.',
            online: 'Please enter Online title.',
            procedure: 'Please enter procedure.',
            doctor: 'Please enter doctor title.',
            patient: 'Please enter patient title.',
        }
    }
};
export const AddPromoModuleMessage = {
    addPromo: {
        required: {
            title: 'Please enter title.',
            code: 'Please enter promo code.',
            desc: 'Please enter description.',
            status: 'Please select any one status.',
            startDate: 'Please select anyone start date.',
            endDate: 'Please select anyone end date.',
            walletAmount: 'Please enter cashback %.',
            payableAmount: 'Please enter cashback amount.',
            maximumNoUsage: 'Please enter maximum number of usage.'
        },
        pattern: {
            code: 'Invalid code.',
        }
    }
};
export const AddLocationModuleMessage = {
    addLocation: {
        required: {
            image: 'Please enter location image.',
            industry: 'Please select any industry.',
            name: 'Please enter name.',
            city: 'Please select  city.',
            address: 'Please enter address.'
        }
    }
};
export const ChangePasswordModuleMessage = {
    changePassword: {
        required: {
            oldPass: 'Please enter an old password.',
            newPass: 'Please enter a new password.',
            confirmPass: 'Please enter a confirm password.'
        },
        invalid: {
            newPass: 'Password must be a minimum 6 characters.'
        },
        match: {
            newAndConfirmPass: 'New password and confirm password doesn’t match.',
            oldAndNewPass: 'New password cannot be the same as old password.'
        },
        incorrect: {
            oldPass: 'Entered old password is incorrect.'
        }
    }
};
export const SecurityPasswordModuleMessage = {
    securityPassword: {
        required: {
            currentPass: 'Please enter current password.',
            newPass: 'Please enter new password.'
        },
        invalid: {
            currentPass: 'Current password should be 6 characters.',
            newPass: 'New password should be 6 characters.'
        }
    }
};
export const AddHealthModuleMessage = {
    addhealth: {
        required: {
            healthName: 'Please add Health Insurance Company.'
        }
    }
};
export const AddCityModuleMessage = {
    addCity: {
        required: {
            cityName: 'Please add city name.',
            CityImage: 'Please upload city image.'
        }
    }
};
export const AddSpecialityModuleMessage = {
    addSpeciality: {
        required: {
            images: 'Please upload specialty image.',
            industry: 'Please select any one industry.',
            name: 'Please enter business/specialty name.',
            specialityBothLang: 'Please add specialty name in both the language.'
        }
    }
};
export const AddWalletModuleMessage = {
    addWallet: {
        required: {
            bothRemoveAndAdd: 'Please select one radio button.',
            amunt: 'Please enter amount.',
            reason: 'Please enter reason.'
        }
    }
};

export const ViewPartnerModuleMessage = {
    rejectionPopup: {
        required: {
            reason: 'Please enter rejection reason.'
        }
    },
    speciality: {
        required: {
            images: 'Please upload image.',
            industry: 'Please select any one industry.',
            specialityText: 'Please enter specialty.'
        }
    },
    subscriptionPlan: {
        required: {
            amount: 'Please enter monthly subscription amount.',
            commission: 'Please enter subscription commission.',
            bookingFees: 'Please enter booking fees.',
            discount: 'Please enter booking discount.'
        }
    }
};
export const EditPartnerModuleMessage = {
    speciality: {
        required: {
            image: 'Please upload image.',
            industry: 'Please select any one industry.',
            specialityText: 'Please enter specialty.'
        }
    },
    awards: {
        required: {
            image: 'Please upload image.',
            name: 'Please enter name.'
        }
    },
    venue: {
        required: {
            image: 'Please upload image.',
            name: 'Name can’t be blank.',
            city: 'Please select city.',
            address: 'Address can’t be blank.',
        }
    },
    partners: {
        required: {
            doctorsName: 'Doctor name can’t be blank.',
            email: 'Email can’t be blank.',
            emailValid: 'Email is Invalid.',
            mobile: 'Mobile number can’t be blank.',
            mobileInvalid  : 'Mobile number is Invalid.',
            specialty  : 'Specialty can’t be blank.'
        }
    },
    services: {
        required: {
            serviceName: 'Service name can’t be blank.',
            serviceCost: 'Service cost can’t be blank.',
            description: 'Description can’t be blank.',
            serviceTime: 'Time can’t be blank.',
        }
    },
    appointment: {
        required: {
            day : 'Select any weekday.',
            from : 'Timings for Venue cant be blank.',
            to : 'Timings for Venue cant be blank.',
        }
    },
    subscriptionPlan: {
        required: {
            image: 'Please upload profile image.',
            cover_image: 'Please upload cover image.',
            firstName: 'Please enter first name.',
            lastName: 'Please enter last name.',
            email: 'Please enter email.',
            amount: 'Please enter subscription amount.',
            commission: 'Please enter subscription commission.',
            mobile: 'Please enter mobile number.',
            industry: 'Please select any one industry.',
            specialty: 'Please select at least one specialty.',
            bookingFees: 'Please enter booking fees.',
            vipBooking: 'Please enter VIP booking fees.',
            discount: 'Please enter discount %.',
            accountName: 'Please enter account name.',
            accountNumber: 'Please enter account number.',
            rnc: 'Please enter RNC/ID.',
            country: 'Please select a country code.',
            companyName: 'Please enter company name.',
            education: 'Please add at least one education.',
            services: 'Please add at least one services.',
            gender: 'Please select any gender.',
            accountType: 'Please select your account type.',
            currencyType: 'Please select your currency',
            description: 'Please enter description.',
            booking: 'Please enter number of booking.'
        },
        invalid: {
            email: 'Please enter valid email.',
            mobile: 'Please enter valid mobile number.',
            RndId: 'RNC/ID must be between 4 to 25 characters.'
        }
    }
};
export const SendNotificationMessages = {
    required: {
        industry_id: 'Please select the industry(s).',
        sms_content: 'Please enter the sms.',
        notification_content: 'Please enter the push notification.',
        email_content: 'Please enter the email.',
        notificationType: 'Please select any one notification type'
    }
};
export const AddPartnerModuleMessage = {
    speciality: {
        required: {
            image: 'Please upload image.',
            industry: 'Please select any one industry.',
            specialityText: 'Please enter specialty.'
        }
    },
    subscriptionPlan: {
        required: {
            image: 'Please upload profile image.',
            firstName: 'Please enter first name.',
            lastName: 'Please enter last name.',
            email: 'Please enter email.',
            amount: 'Please enter monthly subscription amount.',
            commission: 'Please enter subscription commission.',
            mobile: 'Please enter mobile number.',
            industry: 'Please select any one industry.',
            specialty: 'Please select at least one specialty.',
            bookingFees: 'Please enter booking fees.',
            vipBooking: 'Please enter VIP booking fees.',
            discount: 'Please enter discount %.',
            accountName: 'Please enter account name.',
            accountNumber: 'Please enter account number.',
            rnc: 'Please enter RNC/ID.',
            country: 'Please select a country code.',
            companyName: 'Please enter company name.',
            gender: 'Please select any gender.',
            accountType: 'Please select your account type.',
            currencyType: 'Please select your currency.'
        },
        invalid: {
            email: 'Please enter valid email.',
            mobile: 'Please enter valid mobile number.',
            RndId:'RNC/ID must be between 4 to 25 characters.'
        }
    }
};

export const AddContactsModule = {
    contact: {
        required: {
            whataAppNo: 'Whatsapp Number can’t be blank.',
            email: 'Contact Email Address can’t be blank.'
        },
        invalid: {
            validnumber: 'Enter valid Whatsapp Number.',
            validEmail: 'Enter valid Contact Email Address.'
        }
    }
}

export const AddSubAdminModuleMessage = {
    addSubAdmin: {
        required: {
            type: 'Please select type.',
            name: 'Please enter name.',
            email: 'Please enter email address.',
            password: 'Please enter password.',
            selectAdmin: 'Please select at least one module.',
            insurance: 'Please select insurance.'
        },
        invalid: {
            validEmail: 'Enter valid email address.',
            pass: 'Password should be 6 characters alphanumeric.',
        }
    }
};
