import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import 'rxjs/Rx';
import 'rxjs-compat/add/operator/catch';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { CSHelper } from '../../helpers';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    pageTitle: string;
    subject = new Subject<boolean>();
    baseUrl: string = environment.apiBaseUrl;
    baseUrlSecond: string = environment.apiBaseUrlSecond;
    apiAddEmployes: string = environment.apiAddEmployes;
    tokenCompany: string = environment.tokenCompany;
    faqReqUrl: any;
    specialityReqUrl: any;
    industryReqUrl: any;
    cityRequrl: any;
    healthRequrl: any;
    public helperValueUpdated = new BehaviorSubject({ is_updated: false });
    private httpOptions: any;

    constructor(private http: HttpClient, private router: Router, private CSHelper: CSHelper) {
    }

    public isAuthenticated(): boolean {
        const isAuth = JSON.parse(localStorage.getItem('loginData'));
        return !!isAuth;
    }

    // Update Helper Value
    updateHelper(value: boolean): void {
        this.helperValueUpdated.next({ is_updated: value });
    }

    // Reset Helper
    resetHelper(): void {
        this.helperValueUpdated.next({ is_updated: false });
    }

    getBasicHeader() {
        this.httpOptions = new HttpHeaders();
        this.httpOptions.append('Content-Type', 'multipart/form-data');
        this.httpOptions.append('Accept', 'application/json');
        return { headers: this.httpOptions };
    }

    getAuthHeader() {
        const token = JSON.parse(localStorage.getItem('loginData'));
        if (token) {
            this.httpOptions = {
                headers: new HttpHeaders({
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token.token
                }),
            };
        }
        return this.httpOptions;
    }

    getAuthHeaderSecond() {
        this.httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5IiwianRpIjoiMzhlMWYwYTY0NWEzYTM4MDQ0ODAyODQyZjk4MWYzMWEwMWUxNjg1MTcxN2I4ZDQxZmFlMDBhMTJiMzQxOWJiODFhZjIxYWFmMjQyNzBiYTkiLCJpYXQiOjE2NjczNDQ5NDQsIm5iZiI6MTY2NzM0NDk0NCwiZXhwIjoxNjk4ODgwOTQ0LCJzdWIiOiIxNTc4Iiwic2NvcGVzIjpbXX0.zCYbTiPp16DMpFtck3KM2CeTtH9xquogS6zVxg6N9BgcEIs_xfcAWvl3Xm5F3qGxrMFSn7tpymZDfB--mW4i4VBdFRbsCuWIVUxTuG_ee5kIdUH97azZw0C56G6k6T8zc4BWOgX3vvCvEQqz8CkO8RXUybc0X3prU9pwm_zZ0pJ0RdGz0XM2JATLySir0ExoFRyt7-4Bdlc5qs4RHIxLiQ-H702AoOSefahe7QsgbNIU9kRp3J0f65oSk4WeWMKhxnREkTdkVF9Io0TROcesFYNkUSPcdV6nfcAq9onQb_U5tQ8mtVh6SDXVKqKTiy7rAbBkkuYo78H0rLCLevvDcO-5x31J6MDJErreZNbCCBo2RkPBaqcBAN6WHbYqO__yAM4OdGG-5wbDt06w9RQcsa_rPf6Ezoy-jmugrX3gX6Ibf_4KF5nqHTxuGIr3rWxaDuQpMdHecRfzMnRt9PAGTKN8YVz7CRzlCIspc4zsdu1yPpa40pi7uO731tjK9pXqSFMvFmvWsDYaqZsv0UQcKVzHdHwLs_8IFotHjn6u3FVooaAcjCRSTQQukOns0tz2_z8czQgxBwpXW9X6sFwrpRtBuNVnNKn3xipSk0eabKYfZFahRxkcFUHBIwLEMrrXvFlRk-pH5222stBHuxXN262sHe7fKNL9QzyypYZ2yVY'
            }),
        };
        return this.httpOptions;
    }

    getAuthBlobHeader() {
        const user = JSON.parse(localStorage.getItem('loginData'));
        this.httpOptions = {
            headers: new HttpHeaders({ Accept: 'application/json', Authorization: 'Bearer ' + user.token }),
            responseType: 'blob'
        };
        return this.httpOptions;
    }


    /** Authentication Features **/
    login(data): Observable<any> {
        return this.http.post(this.baseUrl + 'login', data, this.getBasicHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    forgotPassword(data): Observable<any> {
        return this.http.post(this.baseUrl + 'forgot-password', data, this.getBasicHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    resetPassword(data): Observable<any> {
        return this.http.post(this.baseUrl + 'reset-password', data, this.getBasicHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    verifyToken(data): Observable<any> {
        return this.http.post(this.baseUrl + 'verify-reset-token', data, this.getBasicHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /* CMS Module */
    getCMSList(): Observable<any> {
        return this.http.get(this.baseUrl + 'cms-list', this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getSingleCMSDetails(name): Observable<any> {
        return this.http.get(this.baseUrl + 'cms-detail/' + name, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    editCMS(data, id): Observable<any> {
        return this.http.post(this.baseUrl + 'cms-edit/' + id, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        );
    }

    /** faq **/
    getFaqList(requestPara) {
        const reqUrl = 'faq-list' + requestPara;
        return this.http.get(this.baseUrl + reqUrl, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getFAQDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'faq-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    editFAQ(id, data): Observable<any> {
        if (this.pageTitle === 'Edit FAQ') {
            this.faqReqUrl = 'faq-add-edit/' + id;
        } else {
            this.faqReqUrl = 'faq-add-edit'
        }
        return this.http.post(this.baseUrl + this.faqReqUrl, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    deleteFqaList(id): Observable<any> {
        const reqUrl = 'faq-delete/' + id;
        return this.http.get(this.baseUrl + reqUrl, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** config **/
    getConfigDetails(): Observable<any> {
        return this.http.get(this.baseUrl + 'config-detail', this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        );
    }

    editConfig(data): Observable<any> {
        return this.http.post(this.baseUrl + 'config-edit', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** Get Admin Details  **/
    getAdminDetails(): Observable<any> {
        return this.http.get(this.baseUrl + 'admin-details', this.getAuthHeader()).pipe(
            catchError((err) => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
        );
    }

    /**  password module **/
    changePassword(data): Observable<any> {
        return this.http.post(this.baseUrl + 'change-password', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    securityPassword(data): Observable<any> {
        return this.http.post(this.baseUrl + 'security-password', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** manage speciality module **/
    getSpecialityList(requestPara) {
        return this.http.get(this.baseUrl + 'speciality-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    deleteSpecialityList(id): Observable<any> {
        const reqUrl = 'speciality-delete/' + id;
        return this.http.get(this.baseUrl + reqUrl, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    editSpeciality(id, data): Observable<any> {
        if (this.pageTitle === 'Edit Specialty') {
            this.specialityReqUrl = 'speciality-add-edit/' + id;
        } else {
            this.specialityReqUrl = 'speciality-add-edit'
        }
        return this.http.post(this.baseUrl + this.specialityReqUrl, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    industryList(): Observable<any> {
        return this.http.get(this.baseUrl + 'active-industry-list', this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getSpeciality(id): Observable<any> {
        return this.http.get(this.baseUrl + 'speciality-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** manage location module **/
    getLocationList(requestPara) {
        return this.http.get(this.baseUrl + 'location-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    deleteLocationList(id): Observable<any> {
        const reqUrl = 'location-delete/' + id;
        return this.http.get(this.baseUrl + reqUrl, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    editLocation(id, data): Observable<any> {
        if (this.pageTitle === 'Edit Location') {
            this.specialityReqUrl = 'location-add-edit/' + id;
        } else {
            this.specialityReqUrl = 'location-add-edit'
        }
        return this.http.post(this.baseUrl + this.specialityReqUrl, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getLocationDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'location-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** common api**/
    cityList(): Observable<any> {
        return this.http.get(this.baseUrl + 'city-list/61', this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }
    getCityLists(): Observable<any> {
        return this.http.get(this.baseUrl + 'city-list', this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** manage industry **/
    getIndustryList(requestPara) {
        return this.http.get(this.baseUrl + 'industry-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        );
    }

    allIndustryLists() {
        return this.http.get(this.baseUrl + 'industry-list', this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        );
    }

    updateStatus(data) {
        return this.http.post(this.baseUrl + 'industry-status', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            })
            ));
    }

    activeIndustryList() {
        return this.http.get(this.baseUrl + 'active-industry-list', this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            })
            ));
    }

    industryAddEdit(data, id): Observable<any> {
        if (this.pageTitle === 'Edit Industry') {
            this.industryReqUrl = 'industry-add-edit/' + id;
        } else {
            this.industryReqUrl = 'industry-add-edit'
        }
        return this.http.post(this.baseUrl + this.industryReqUrl, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            })
            ));
    }

    getIndustryDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'industry-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** city Module **/
    getcityList(requestPara): Observable<any> {
        return this.http.get(this.baseUrl + 'city-list-all' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    deleteCityList(id): Observable<any> {
        const reqUrl = 'city-delete/' + id;
        return this.http.get(this.baseUrl + reqUrl, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    addEdiCites(id, data): Observable<any> {
        if (this.CSHelper.buttonTitle === 'Update') {
            this.cityRequrl = 'city-add-edit/' + id;
        } else {
            this.cityRequrl = 'city-add-edit'
        }
        return this.http.post(this.baseUrl + this.cityRequrl, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            })
            ));
    }

    getCityDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'city-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** health insurance module  **/
    getHealthInsuranceList(requestPara): Observable<any> {
        return this.http.get(this.baseUrl + 'health-insurance-company-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /**medication list module  **/
    getMedicationList(requestPara): Observable<any> {
        return this.http.get(this.baseUrl + 'medication-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    deleteMedication(id): Observable<any> {
        const reqUrl = 'health-insurance-company-delete/' + id;
        return this.http.get(this.baseUrl + reqUrl, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    deleteHealthInsuranceList(id): Observable<any> {
        const reqUrl = 'health-insurance-company-delete/' + id;
        return this.http.get(this.baseUrl + reqUrl, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    addEditHealthInsurance(data, id): Observable<any> {
        if (this.CSHelper.buttonTitle === 'Update') {
            this.healthRequrl = 'health-insurance-company-add-edit/' + id;
        } else {
            this.healthRequrl = 'health-insurance-company-add-edit'
        }
        return this.http.post(this.baseUrl + this.healthRequrl, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            })
            ));
    }

    addEditMedication(data, id): Observable<any> {
        if (this.CSHelper.buttonTitle === 'Update') {
            this.healthRequrl = 'medication-add-edit/' + id;
        } else {
            this.healthRequrl = 'medication-add-edit'
        }
        return this.http.post(this.baseUrl + this.healthRequrl, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            })
            ));
    }

    changeMedicationStatus(data): Observable<any> {
        return this.http.post(this.baseUrl + 'change-medication-status', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getHealthInsuranceDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'health-insurance-company-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }


    /** manage promoCode module **/
    getPromoCodeList(requestPara) {
        return this.http.get(this.baseUrl + 'promocode-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getPromoCodeDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'promocode-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    addEditPromoCode(id, data): Observable<any> {
        return this.http.post(this.baseUrl + 'promocode-add-edit' + id, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    changePromoCodeStatus(data): Observable<any> {
        return this.http.post(this.baseUrl + 'promocode-status', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    deletePromoCode(id): Observable<any> {
        const reqUrl = `promocode-delete/${id}`;
        return this.http.get(this.baseUrl + reqUrl, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    exportPromocode(data): Observable<any> {
        return this.http.get(this.baseUrl + 'promocode-list-export' + data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }


    /** manage Advertisement module **/
    getAdvertisementList(requestPara) {
        return this.http.get(this.baseUrl + 'advertisement-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    addEditAdvertisement(id, data): Observable<any> {
        return this.http.post(this.baseUrl + 'advertisement-add-edit' + id, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getAdvertisementDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'advertisement-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    hideAllAdsPostApi(data) {
        return this.http.post(this.baseUrl + 'advertisement-config', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    changeAdvertisementStatus(data): Observable<any> {
        return this.http.post(this.baseUrl + 'advertisement-activate-deactivate', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    exportAdvertisement(data): Observable<any> {
        return this.http.get(this.baseUrl + 'advertisement-export' + data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    /** contact us**/
    updateContact(data): Observable<any> {
        return this.http.post(this.baseUrl + 'contact-us', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** Mange Partners **/
    getPartnersList(requestPara) {
        return this.http.get(this.baseUrl + 'partner-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** Mange Company **/
    getCompanyList(requestPara) {

        return this.http.get(this.baseUrlSecond + 'list-company', this.getAuthHeaderSecond()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
        ));
    }

    changePartnersStatus(data): Observable<any> {
        return this.http.post(this.baseUrl + 'partner-status', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    addPartner(data): Observable<any> {
        return this.http.post(this.baseUrl + 'partner-add', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    // Agregar compañia - Fernando
    /*addCompany(data): Observable<any> {
        console.log(data)
        return  this.http.post(this.baseUrl + 'partner-add', data, this.getAuthHeader()).pipe(
            catchError((err => {
                    this.CSHelper.handleAuthError(err);
                    return throwError(err)
                })
            ));
    }*/

    getCompanyLists(): Observable<any> {
        return this.http.get(this.apiAddEmployes + 'list-company-only', {
            headers: {
              Authorization: this.tokenCompany
            }}).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
        ));
    }

    addCompany(data): Observable<any> {
        return this.http.post(this.apiAddEmployes + 'add-company', data,
          {
            headers: {
              Authorization: this.tokenCompany
            },
        });
    }

    // Agregar empleados - Fernando
    addEmployess(data): Observable<any> {
        return this.http.post(this.apiAddEmployes + 'add-employee', 
        {
            company_id: 1,
            employees: data
          },
          {
            headers: {
              Authorization: this.tokenCompany
            },
        });
    }

    addLeaderCompany(data): Observable<any> {
        console.log(data)
        return this.http.post(this.apiAddEmployes + 'add-employee', 
        {
            company_id: data.companyName,
            role: 1,
            employees: [{
                first_name: data.HrLeaderName, 
                last_name: data.HrLeaderLastName, 
                email: data.email, 
                phone: data.phoneNumer, 
                city: data.city, 
                gender: data.gender, 
                employment_type: 3,
                employee_code: 1
            }]
          },
          {
            headers: {
              Authorization: this.tokenCompany
            },
        });
    }

    deleteLeader(data): Observable<any> {
        console.log(data)
        return this.http.post(this.apiAddEmployes + 'delete-company-leader', 
        {
            id: data,
          },
          {
            headers: {
              Authorization: this.tokenCompany
            },
        });
    }

    EditLeaderCompany(data, id: number): Observable<any> {
        console.log(data)
        // Hya que crear el endPoint 2023-01-16
        return this.http.post(this.apiAddEmployes + 'edit-company-leader', 
        {
            id: id,
            name: data.EditHrLeaderName,
            email: data.Editemail
          },
          {
            headers: {
              Authorization: this.tokenCompany
            },
        });
    }

    // Agregar empleados - Fernando
    getEmployeesList(): Observable<any> {
        return this.http.get(this.apiAddEmployes + 'list-employee/company/1', 
          {
            headers: {
              Authorization: this.tokenCompany
            },
        });
    }

    getSpecialityByIndustry(id): Observable<any> {
        return this.http.get(this.baseUrl + 'industry-speciality-list/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getBankList(): Observable<any> {
        return this.http.get(this.baseUrl + 'bank-list', this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    editSpecialityPartner(id, data): Observable<any> {
        if (id !== '') {
            this.specialityReqUrl = 'speciality-add-edit/' + id;
        } else {
            this.specialityReqUrl = 'speciality-add-edit'
        }
        return this.http.post(this.baseUrl + this.specialityReqUrl, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    exportPartnerList(data): Observable<any> {
        return this.http.get(this.baseUrl + 'partner-list-export' + data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    getPartnerDetails(user_id): Observable<any> {
        return this.http.get(this.baseUrl + 'partner-details/' + user_id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    getServiceProvider(id): Observable<any> {
        return this.http.get(this.baseUrl + 'service-provider-list/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    assistantList(id): Observable<any> {
        return this.http.get(this.baseUrl + 'assistant-list/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    getPartneBookingList(id): Observable<any> {
        return this.http.get(this.baseUrl + 'partner-booking-list/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    /** partner request module **/
    getPartnersRequestList(requestPara): Observable<any> {
        return this.http.get(this.baseUrl + 'partner-request' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getPartnersRequestDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'partner-request-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    partnerRequestAccept(id, data): Observable<any> {
        return this.http.post(this.baseUrl + 'partner-request-accept/' + id, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    partnerRequestReject(id, data) {
        return this.http.post(this.baseUrl + 'partner-request-reject/' + id, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    exportPartnerRequest(data): Observable<any> {
        return this.http.get(this.baseUrl + 'partner-request-export' + data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    countryList(): Observable<any> {
        return this.http.get(this.baseUrl + 'country-list', this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** customer list **/
    getCustomerList(requestPara): Observable<any> {
        return this.http.get(this.baseUrl + 'customer-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    exportCustomer(data): Observable<any> {
        return this.http.get(this.baseUrl + 'customer-list-export' + data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    changeCustomerStatus(data): Observable<any> {
        return this.http.post(this.baseUrl + 'change-customer-status', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    addWallet(data): Observable<any> {
        return this.http.post(this.baseUrl + 'add-remove-wallet-amount', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    walletHistory(requestPara): Observable<any> {
        const reqUrl = 'wallet-activities/' + requestPara.id + '?page=' + requestPara.page + '&per_page=' + requestPara.per_page;
        return this.http.get(this.baseUrl + reqUrl, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    bookingList(requestPara): Observable<any> {
        return this.http.get(this.baseUrl + 'booking-list/' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    customerDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'customer-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    // Mange top 10 partner Module //
    getTopIndustryList(id): Observable<any> {
        return this.http.get(this.baseUrl + 'top-industry-list?city_id=' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getTopSpecialityList(cityId, industryId): Observable<any> {
        return this.http.get(`${this.baseUrl}top-speciality-list?city_id=${cityId}&industry_id=${industryId}`, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getTopPartnerList(requestPara): Observable<any> {
        return this.http.get(`${this.baseUrl}top-partner-list${requestPara}`,
            this.getAuthHeader()).pipe(
                catchError((err => {
                    this.CSHelper.handleAuthError(err);
                    return throwError(err)
                })
                ));
    }

    getTop10PartnerList(requestPara): Observable<any> {
        return this.http.get(`${this.baseUrl}top-ten-partner-list${requestPara}`,
            this.getAuthHeader()).pipe(
                catchError((err => {
                    this.CSHelper.handleAuthError(err);
                    return throwError(err)
                })
                ));
    }

    addPartnerToTopList(data): Observable<any> {
        return this.http.post(`${this.baseUrl}add-to-top-ten`, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    removePartnerFromTopList(id): Observable<any> {
        return this.http.get(`${this.baseUrl}remove-from-top-ten/${id}`,
            this.getAuthHeader()).pipe(
                catchError((err => {
                    this.CSHelper.handleAuthError(err);
                    return throwError(err)
                })
                ));
    }

    /** referrer module **/
    getReferredList(requestPara): Observable<any> {
        return this.http.get(this.baseUrl + 'referred-doctor-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    changeReferredStatus(data): Observable<any> {
        return this.http.post(this.baseUrl + 'change-referrer-status', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    exportReferredDoctors(data): Observable<any> {
        return this.http.get(this.baseUrl + 'referred-export' + data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    deleteDoctorList(id): Observable<any> {
        return this.http.get(this.baseUrl + 'referrer-delete/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** Dashboard api **/
    dashboard(data): Observable<any> {
        return this.http.get(this.baseUrl + 'dashboard' + data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    addEditAwards(data): Observable<any> {
        return this.http.post(this.baseUrl + 'awards-add-edit', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getAwardsList(id): Observable<any> {
        return this.http.get(this.baseUrl + 'awards-list/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getVenueList(id): Observable<any> {
        return this.http.get(this.baseUrl + 'venue-list/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getMainLocationList(id): Observable<any> {
        return this.http.get(this.baseUrl + 'main-location-list' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    deleteAwards(id): Observable<any> {
        return this.http.get(this.baseUrl + 'awards-delete/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    doctorList(id): Observable<any> {
        return this.http.get(this.baseUrl + 'doctor-list/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    editPartner(data, id): Observable<any> {
        return this.http.post(this.baseUrl + 'partner-edit/' + id, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    venueAdd(data): Observable<any> {
        return this.http.post(this.baseUrl + 'venue-add', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    // get Booking List
    getBookingList(data): Observable<any> {
        return this.http.post(this.baseUrl + 'booking-list', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    // get booking List Details
    getBookingDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'booking-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    // export booking list
    exportBookingList(data): Observable<any> {
        return this.http.post(this.baseUrl + 'booking-list-export', data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    exportBookingListTwo(data): Observable<any> {
        return this.http.post(this.baseUrl + 'booking-list-exportTwo', data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    getDoctorList(search): Observable<any> {
        return this.http.get(this.baseUrl
            + 'doctor-list/2' + search, this.getAuthHeader()).pipe(
                catchError((err => {
                    this.CSHelper.handleAuthError(err);
                    return throwError(err)
                })
                ));
    }

    // Notification Module
    getNotificationList(data): Observable<any> {
        return this.http.get(`${this.baseUrl}list-send-notification` + data, this.getAuthHeader()).pipe(
            catchError((err) => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            }));
    }

    authorizeAdmin(data): Observable<any> {
        return this.http.post(`${this.baseUrl}verify-security-password`, data, this.getAuthHeader()).pipe(
            catchError((err) => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            }));
    }

    sendNotification(data): Observable<any> {
        //${this.baseUrl}
        return this.http.post(`${this.baseUrl}send-notification`, data, this.getAuthHeader()).pipe(
            catchError((err) => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            }));
    }

    sendNotificationTopic(data, messageSend): Observable<any> {

        let dataSend = {
            "to": "/topics/fcm_customer",
                "notification" : {
                "body" : messageSend,
                "title": "Virtual Doc"
            }
        }

        return this.http.post('https://fcm.googleapis.com/fcm/send', dataSend, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'key=AAAABoj_dvA:APA91bH2V_z60PvC1oy9RjTW-Xosdmb6BOroWe4ppH_MJuSkSD-ce-NPSc0VLh3jmUbQrsZmtgQHU4EC0jxmIKVH-6awFHPwVTOUyIKA_tX8zTGPyFrqc95AMmPTN2xG0JN-6sRQO2Tl',
            }
        })
    }



    addEditAssistant(data, id): Observable<any> {
        return this.http.post(`${this.baseUrl}assistant-add-edit` + id, data, this.getAuthHeader()).pipe(
            catchError((err) => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            }));
    }

    assistantDeleet(pId, id): Observable<any> {
        const reqUrl = 'assistant-delete/' + pId + '/' + id;
        return this.http.get(this.baseUrl + reqUrl, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    sendPayoutList(requestPara, type): Observable<any> {
        const apiUrl = type == 1 ? 'send-payout-list' : 'received-payout-list'
        return this.http.get(this.baseUrl + apiUrl + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }
    payoutList(requestPara): Observable<any> {
        return this.http.get(this.baseUrl + 'payout-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    exportSendPayoutList(data): Observable<any> {
        return this.http.get(this.baseUrl + 'export-send-payout-list' + data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }
    exportPayoutList(data): Observable<any> {
        return this.http.get(this.baseUrl + 'export-payout-list' + data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }

    markAsPaid(data): Observable<any> {
        return this.http.post(`${this.baseUrl}mark-as-paid`, data, this.getAuthHeader()).pipe(
            catchError((err) => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            }));
    }
    monthlyMarkAsPaid(data): Observable<any> {
        return this.http.post(`${this.baseUrl}monthly-mark-as-paid`, data, this.getAuthHeader()).pipe(
            catchError((err) => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            }));
    }

    addLocationToPartner(id, locationId) {
        return this.http.get(this.baseUrl + 'add-location-to-partner/' + id + '?location_id=' + locationId, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** manage invoice module **/
    invoiceMarkAsPaid(data): Observable<any> {
        return this.http.post(`${this.baseUrl}invoice/mark-as-paid`, data, this.getAuthHeader()).pipe(
            catchError((err) => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            }));
    }

    getInvoiceList(requestPara) {
        return this.http.get(this.baseUrl + 'invoice-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    activeHealthInsuranceList(): Observable<any> {
        return this.http.get(this.baseUrl + 'active-health-insurance-list', this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getInvoiceDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'invoice-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    medicationDelete(id): Observable<any> {
        const reqUrl = 'medication-delete/' + id;
        return this.http.get(this.baseUrl + reqUrl, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    /** manage sub admin module **/
    getSubAdminList(requestPara) {
        return this.http.get(this.baseUrl + 'sub-admin-list' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    editSubAdmin(id, data): Observable<any> {
        if (this.pageTitle === 'Edit Sub Admin') {
            this.specialityReqUrl = 'sub-admin-add-edit/' + id;
        } else {
            this.specialityReqUrl = 'sub-admin-add-edit'
        }
        return this.http.post(this.baseUrl + this.specialityReqUrl, data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getSubAdminDetails(id): Observable<any> {
        return this.http.get(this.baseUrl + 'sub-admin-detail/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    getMasterModuleList(): Observable<any> {
        return this.http.get(this.baseUrl + 'module-access-list', this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    deleteSubAdminList(id): Observable<any> {
        return this.http.get(this.baseUrl + 'sub-admin-delete/' + id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }

    changeStatusSubAdminList(data): Observable<any> {
        return this.http.post(this.baseUrl + 'change-sub-admin-status', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err);
            })
            ));
    }

    payoutDetails(requestPara): Observable<any> {
        return this.http.get(this.baseUrl + 'payout-details' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }
    exportPayoutDetails(data): Observable<any> {
        return this.http.get(this.baseUrl + 'export-payout-details' + data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }
    exportInvoiceList(data): Observable<any> {
        return this.http.post(this.baseUrl + 'export-invoice-list', data, this.getAuthBlobHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }
    medicalHistory(requestPara): Observable<any> {
        return this.http.get(this.baseUrl + 'medical-history-list/' + requestPara, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            })
            ));
    }
    getPrescription(user_id): Observable<any> {
        return this.http.get(this.baseUrl + 'medical-history-detail/' + user_id, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }
    healthInsuranceCompanyStatus(data): Observable<any> {
        return this.http.post(this.baseUrl + 'health-insurance-company-status', data, this.getAuthHeader()).pipe(
            catchError((err => {
                this.CSHelper.handleAuthError(err);
                return throwError(err)
            }))
        )
    }
}
