import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from "../services/api.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ChangePasswordModuleMessage} from '../Validation/message';
import {CSHelper} from "../../helpers";

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
    changePasswordForm: FormGroup;
    submitted = false;
    message = ChangePasswordModuleMessage;
    hide = true;
    hide1 = true;
    hide2 = true;

    constructor(private api: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder, private CSHelper: CSHelper) {
    }

    ngOnInit(): void {
        this.changePasswordForm = this.formBuilder.group({
            oldPassword: ['', [Validators.required, this.CSHelper.spaceValidator]],
            newPassword: ['', [Validators.required, this.CSHelper.spaceValidator, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required, this.CSHelper.spaceValidator]],
        }, {
            validator: [PasswordValidation.OldMatchPassword, PasswordValidation.NewMatchPassword] // your validation method
        });
    }

    get f() {
        return this.changePasswordForm.controls;
    }

    changePassForm() {
        this.submitted = true;
        if (this.changePasswordForm.valid) {
            const formData = new FormData();
            formData.append('old_password', this.changePasswordForm.value.oldPassword);
            formData.append('password', this.changePasswordForm.value.newPassword);
            this.api.changePassword(formData).subscribe((res) => {
                this.CSHelper.hideSpinner();
                if (res.meta.code === 1) {
                    this.CSHelper.showSuccessMessage(res.meta.message);
                    this.changePasswordForm.reset();
                    this.modalService.dismissAll();
                } else {
                    this.CSHelper.showErrorMessage(res.meta.message);
                }
            }, error1 => {
                this.CSHelper.hideSpinner();
            });

        }
    }

    // Open modal with dark section
    openModal(changePassword) {
        this.modalService.open(changePassword, {windowClass: 'dark-modal'});
    }

    closebutton() {
        this.changePasswordForm.reset();
        this.modalService.dismissAll();
    }


}

/**

 Confirm password *
 @param {AbstractControl} control
 @returns {{passwordsNotMatch: boolean}}
 */
export class PasswordValidation {

    static OldMatchPassword(AC: AbstractControl) {

        const password = AC.get('newPassword').value; // to get value in input tag
        const oldPassword = AC.get('oldPassword').value; // to get value in input tag
        if (AC.get('newPassword').errors && !AC.get('newPassword').errors.OldMatchPassword) {

            // return if another validator has already found an error on the matchingControl
            return;
        }
        if (password === oldPassword) {
            AC.get('newPassword').setErrors({OldMatchPassword: true});

        } else {
            AC.get('newPassword').setErrors(null);
        }
    }

    static NewMatchPassword(AC: AbstractControl) {

        const password = AC.get('newPassword').value; // to get value in input tag
        const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
        if (AC.get('confirmPassword').errors && !AC.get('confirmPassword').errors.NewMatchPassword) {
            return;
        }
        if (password !== confirmPassword) {
            AC.get('confirmPassword').setErrors({NewMatchPassword: true});
        } else {
        }
    }
}

