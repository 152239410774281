import {Component, OnInit, Input, ViewChild, OnDestroy, ElementRef, Renderer2, AfterViewInit} from "@angular/core";

import {ROUTES} from './sidebar-routes.config';
import {Router, ActivatedRoute} from "@angular/router";
import {ConfigService} from '../services/config.service';
import {Subscription} from 'rxjs';
import {customAnimations} from "../animations/custom-animations";
import {ApiService} from "../services/api.service";
import {CSHelper} from "../../helpers";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('toggleIcon') toggleIcon: ElementRef;
    public menuItems: any[];
    depth: number;
    activeTitles: string[] = [];
    expanded: boolean;
    nav_collapsed_open = false;
    logoUrl = 'assets/img/logo.png';
    public config: any = {};
    layoutSub: Subscription;
    loginData: any;


    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private router: Router,
        private route: ActivatedRoute,
        private configService: ConfigService,
        private _apiService: ApiService,
        private CSHelper: CSHelper
    ) {
        if (this.depth === undefined) {
            this.depth = 0;
            this.expanded = true;
        }

    }


    ngOnInit() {
        this.config = this.configService.templateConf;
        this.menuItems = [];
        this.menuItems = ROUTES;
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this._apiService.getAdminDetails().subscribe((res: any) => {
            if (res.meta.code === 1) {
                if (res.data.status === 1) {
                    this.CSHelper.email = res.data.email;
                    this.CSHelper.name = res.data.name;
                    this._apiService.updateHelper(true);
                    this.loginData = res.data;
                    if (this.loginData && this.loginData.type !== 1) {
                        const access_modules = this.loginData.modules;
                        /*let checkDashboard: boolean;
                        this.loginData.modules.forEach((value, index) => {
                            if (value.slug === 'dashboard') {
                                checkDashboard = true;
                            }
                        })*/
                        /*if (!checkDashboard) {
                            this.loginData.modules.push({
                                id: 1,
                                name: 'Dashboard',
                                slug: 'dashboard',
                            })
                        }*/
                        this.menuItems.filter((c) => {
                            if (this.filterItems(access_modules, c.title).length === 0 ) {
                                c.hidden = true;
                            } else {
                                c.hidden = false;
                            }
                        });
                    } else {
                        this.menuItems.filter((c) => {
                            if (this.filterItemsAdmin(this.menuItems, c.title).length === 0 ) {
                                c.hidden = true;
                            } else {
                                c.hidden = false;
                            }
                        });

                    }
                } else {
                    this.CSHelper.clearLocastorage();
                    this.CSHelper.redirectToPage('/login');
                }
            } else {
                this.CSHelper.showErrorMessage(res.meta.message);
            }
        }, error => {})
        if (this.config.layout.sidebar.backgroundColor === 'white') {
            this.logoUrl = 'assets/img/logo.png';
        } else {
            this.logoUrl = 'assets/img/logo.png';
        }


    }
    filterItemsAdmin(arr, query) {
        return arr.filter(function (el) {
            return el.title.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
    }
    filterItems(arr, query) {
        return arr.filter(function (el) {
            return el.name.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
    }

    ngAfterViewInit() {

        setTimeout(() => {
            if (this.config.layout.sidebar.collapsed != undefined) {
                if (this.config.layout.sidebar.collapsed === true) {
                    this.expanded = false;
                    this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                    this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                    this.nav_collapsed_open = true;
                } else if (this.config.layout.sidebar.collapsed === false) {
                    this.expanded = true;
                    this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
                    this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
                    this.nav_collapsed_open = false;
                }
            }
        }, 0);


    }

    ngOnDestroy() {
        this._apiService.resetHelper();
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
    }

    toggleSlideInOut() {
        this.expanded = !this.expanded;
    }

    handleToggle(titles) {
        this.activeTitles = titles;
    }

    // NGX Wizard - skip url change
    ngxWizardFunction(path: string) {
        if (path.indexOf("forms/ngx") !== -1)
            this.router.navigate(["forms/ngx/wizard"], {skipLocationChange: false});
    }

    getPendingRequestStatus() {
        const t = JSON.parse(localStorage.getItem('pendingRequest'));
        if (Number(t) === 1) {
            return true;
        } else {
            return false;
        }
    }
}
