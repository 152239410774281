import {Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {ConfigService} from '../services/config.service';
import {ApiService} from "../services/api.service";
import {CSHelper} from "../../helpers";


@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
    placement = "bottom-right";
    public isCollapsed = true;
    hiddenNav = false;
    layoutSub: Subscription;
    @Output()
    toggleHideSidebar = new EventEmitter<Object>();
    public config: any = {};

    constructor(private configService: ConfigService, public api: ApiService,private CSHelper:CSHelper) {
    }

    ngOnInit() {
        this.config = this.configService.templateConf;
        const user = JSON.parse(localStorage.getItem('loginData'));
        this.hiddenNav = (user.data && user.data.type === 1) ?? true;
    }

    ngAfterViewInit() {
        if (this.config.layout.dir) {
            setTimeout(() => {
                const dir = this.config.layout.dir;
                if (dir === "rtl") {
                    this.placement = "bottom-left";
                } else if (dir === "ltr") {
                    this.placement = "bottom-right";
                }
            }, 0);

        }
    }

    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
    }

    toggleSidebar() {
        const appSidebar = document.getElementsByClassName("app-sidebar")[0];
        if (appSidebar.classList.contains("hide-sidebar")) {
            this.toggleHideSidebar.emit(false);
        } else {
            this.toggleHideSidebar.emit(true);
        }
    }
    logout(): void{
        this.CSHelper.clearLocastorage();
        this.CSHelper.redirectToPage('login');
    }
}
